<template>
  <div class="cummode" v-if = "paramsData.key == '小平房村简介' || paramsData.key == '小平房村委员会' && show">
    <div class="cun_inner">
      <div class="modeTop">
        <div class="flex_center_align">
          <div style="margin-left:16px">{{paramsData.key}}</div>
        </div>
        <div class="modeTop_close" @click="close">
          <img class="mode_img" src="@/assets/img/close1.png"/>
        </div>
      </div>
      <div class="box" >
        <div class="word ql-editor" style="white-space:pre-wrap;" v-html="paramsData.articleContent"></div>
      </div>
    </div>
    <wg-mode ref="wgMode"></wg-mode>
  </div>
  <div class="cummode" v-else-if="show">
    <div class="cun_inner" >
      <div class="modeTop">
        <div class="flex_center_align">
          <div style="margin-left:16px">{{paramsData.title}}</div>
        </div>
        <div class="modeTop_close" @click="close">
          <img class="mode_img" src="@/assets/img/close1.png"/>
        </div>
      </div>
      <div class="box">
        <div v-for="(item) in checkList.list ">
          <div class="word ql-editor" v-html="item.articleContent" style="white-space:pre-wrap;"></div>
        </div>
      </div>
    </div>
    <wg-mode ref="wgMode"></wg-mode>
  </div>
</template>

<script>
import WgMode from './wgMode.vue'
import { getDangIndex, getResidentDetail, getResidents } from '@/api/api.js'
import { Loading } from 'element-ui'
export default {
  components: { WgMode },
  data() {
    return {
      show: false,
      word: '',
      imgList: [],
      title: '',
      paramsData: '',
      lx: '',
      tableList: [],
      content: '',
      numsForm: { familyNum: 0, residentNum: 0, flowNum: 0 },
      optDspLineList: [],
      itemtype: '',
      loading: true,
      checkList: []
    }
  },
  methods: {
    initData(data,industrys) {
      this.loading = true
      this.paramsData = data
      console.log(data)
      console.log(industrys)
      this.checkList = industrys.checkList.find((el) => el.title === data.title);
      console.log(this.checkList)

      //更新党支部人员基本情况
      // getDangIndex({ branchId: data.id }).then(res => {
      //   let memberCount = res.model.memberCount
      //   this.$set(this.paramsData, 'formalMember', memberCount.formalMember)
      //   this.$set(this.paramsData, 'prepareMember', memberCount.prepareMember)
      //   this.$set(this.paramsData, 'developMember', memberCount.developMember)
      //   this.$set(this.paramsData, 'flowMember', memberCount.flowMember)
      //   this.$set(this.paramsData, 'bedMember', memberCount.bedMember)
      //   this.$set(this.paramsData, 'armyMember', memberCount.armyMember)
      // })
      this.title = data.name
      this.word = data.content
      this.content = data.articleContent || data.introduction || data.word
      // this.imgList = data.imgList;
      this.show = true
      this.lx = data.elementType ? data.elementType : ''
      if ((data?.type && data.type == 'gridType') || this.itemtype) {
        // this.getallResident(data.pid ? '' : data.villageId, data.id)
        //传值修改
        console.log(data)

        this.getallResident(data.id, data.villageId, data.ids)
      }
      this.loading = false
      // this.tableList = data.tableList;
    },
    close() {
      this.show = false
      this.optDspLineList = []
      this.paramsData = {}
      this.itemtype = ''
    },
    handleShow(event) {
      getResidentDetail({ familyId: event.id }).then(res => {
        if (res.code == 0) {
          if (res.model && res.model.length) {
            this.$refs.wgMode.initData(event)
            this.$refs.wgMode.wgList = res.model
          } else {
            this.$message.error('当前户无人居住')
          }
        }
      })
    },

    getallResident(pid, villageId, ids) {
      this.loading = true
      getResidents({ pid: pid, villageId: villageId, ids: ids }).then(res => {
        if (res.code == '0') {
          console.log(res.model)
          if (res.model.length) {
            res.model.map(resmode => {
              if (resmode.familyMaintainList.length) {
                resmode.familyMaintainList.map(i => {
                  i.managementFull = i.managementName + i.houseNumber
                  i.storehouseNumber = i?.store ? i.store : i.houseNumber
                })
              }
            })
          }
          this.numsForm = res.model
          this.loading = false
        } else {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cummode {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  color: #fff;
  .cun_inner {
    width: 1139px;
    height: 734px;
    position: absolute;
    left: 375px;
    top: 36px;
    z-index: 1000;
    padding: 62px 6px;
    border-radius: 10px;
    animation-name: small-to-big;
    animation-duration: 1s;
    animation-fill-mode: forwards;  
    @keyframes small-to-big {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }

    .modeTop {
      width: 100%;
      height: 42px;
      background-image: url('../assets/img/titlebg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: JDLangZhengTi;
      font-size: 26px;
      font-weight: 400;
      margin-bottom: 7px;
      color: #ffff;

      &_img {
        margin-left: 14px;
      }
      &_close {
        width: 45px;
        height: 45px;
        margin-right: 8px;
      }
    }












    .main_title {
      color: #fff;
      text-align: center;
      font-size: 30px;
      font-weight: 600;
    }
    .title {
      font-size: 22px;
      color: #fff;
      text-align: left;
      padding: 10px 20px;
      .cun_num {
        flex: 1;
        text-align: center;
      }
    }
    .se_title {
      margin: 10px 0;
      font-size: 22px;
    }
    .box {
      padding: 20px 0;
      width: 100%;
      height: 685px;
      overflow-y: scroll;
      background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);
      .party_nums {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 8px auto;
        .party_item {
          flex: 1;
        }
        .party_title {
          display: inline-block;
          width: 58%;
          text-align: right;
        }
      }
      .new_title {
        padding: 10px 0;
        font-weight: 800;
        margin: 20px auto 0;
      }
      .word {
        font-size: 16px;
        color: #fff;
        line-height: 30px;
        // text-indent: 2em;
        video {
          width: 180px;
          height: 180px;
        }
        img {
          width: 180px;
          height: 180px;
          background: #adffe6;
          border: 4px solid #707070;
          border-radius: 2px;
          margin-left: 10px;
          margin-bottom: 20px;
        }
      }
      .word /deep/ img {
        width: 96%;
        height: 100%;
        background: #adffe6;
        border: 4px solid #707070;
        border-radius: 2px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
      .word /deep/ video {
        width: 96%;
        height: 100%;
        background: #adffe6;
        border: 4px solid #707070;
        border-radius: 2px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
      .word /deep/ table {
        width: 100% !important;
        box-sizing: border-box !important;
        border-right: 1px solid grey !important;
        border-bottom: 1px solid grey !important;
        border-spacing: 0px !important;
        tbody {
          border-spacing: 0px !important;
        }
        tbody tr:nth-child(1) {
          text-align: center !important;
        }
        th {
          padding: 4px !important;
          font-size: 15px !important;
          font-weight: bold !important;
          border: 1px solid grey !important;
        }
        tr {
          border-color: inherit !important;
          border: 1px solid grey !important;
        }
        td {
          padding: 4px !important;
          font-size: 14px !important;
          border-top: 1px solid grey !important;
          border-left: 1px solid grey !important;
          text-align: center !important;
        }
      }

      .imgbox {
        margin-top: 20px;
        flex-wrap: wrap;
        .video {
          width: 100%;
          height: 100%;
        }
        .img_item {
          width: 180px;
          height: 180px;
          background: #adffe6;
          border: 4px solid #707070;
          border-radius: 2px;
          margin-left: 10px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .table_box {
      height: auto;
      padding: 0 10px 20px;
    }
    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 7px;
      z-index: 10000;
      font-size: 29px;
      color: #fff;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
    }
    .nclose {
      font-size: 42px;
      right: 25px;
      top: 15px;
    }
  }
}
.box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #0d53b7;
  -webkit-box-shadow: inset 0 0 5px #0d53b7;
  background: #535353;
}
.box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: transparent;
}
.dyth {
  font-size: 14px;
  text-align: center;
  padding: 5px;
  color: red;
}
.dytd {
  text-align: center;
  padding: 5px;
}
.redcolor {
  color: red !important;
}
.jieimg {
  max-width: 100%;
  height: auto;
}
</style>
<style lang="scss">
.el-table {
  background-color: transparent !important;
}
.el-table th.el-table__cell,
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: transparent !important;
}
.el-table tr {
  background-color: transparent !important;
}
.el-table .cell {
  color: #fff;
}
.el-table th.el-table__cell > .cell {
  color: #fff;
}
.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: transparent !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px !important; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  -webkit-box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  background: #535353 !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px !important;
  background: #ededed !important;
}
</style>
